// See https://gitlab.ridedev.io/shared/front-end/camelot/camelot-webview-utils/-/blob/main/src/functions/webview-utils.ts?ref_type=heads

/**
 * Returns the version of the Android/iOS app. If the version is not available for any reason, it will return
 * an empty string instead.
 *
 * @param {Window} container - webview window container
 * @returns {string} appVersion - '4.31.0' (standard app) or 'SNAPSHOT #somethingHere (DD.MM.YY)' (developer app)
 */
export function getAppVersion(container: Window): string {
  return container.android?.appVersion() || container.app?.appVersion || '';
}

/**
 * Returns **true** in case the web application is running in a webview environment, otherwise **false**.
 * In addition, it's possible to check if the application is running in a WebView on a specific platform.
 *
 * For local development you can add *?is_webview=true* to you application url to force this function
 * to return **true**.
 *
 * @param {Window} container - webview window container
 * @param {string} platform - optional platform check (possible values "android", "webkit")
 * @returns {boolean} isWebView
 */

export function checkIsWebView(container: Window, platform?: 'android' | 'webkit'): boolean {
  const isDebugEnabled = new URLSearchParams(container.location?.search || '').get('is_webview') === 'true';
  const isWebView = container.navigator.userAgent?.toLowerCase()?.includes('webview') || isDebugEnabled;
  return platform ? isWebView && platform in container : isWebView;
}

/**
 * Returns platform where booking is made
 *
 * @param {window} window - webview window container
 * @returns {string} platform
 */
export function getAppPlatform(window: Window): string {
  if (checkIsWebView(window, 'webkit')) {
    return 'iOS';
  } else if (checkIsWebView(window, 'android')) {
    return 'android';
  } else {
    return 'web';
  }
}
